import tick from "../../images/tick.svg"
import segAccounts from "../../images/segragated-accounts.jpg"

export default function SegregatedAccounts() {
  return (
    <>
      <section className="lg:relative p-10 lg:px-0 lg:pt-20">
        <h2
          id="segregated-accounts"
          className="lg:absolute left-0 top-10 lg:w-1/2 px-5 lg:rounded-r-full lg:text-center xl:px-0"
        >
          Segregated Accounts
        </h2>
        <div className="underline"></div>

        <div className="trade-oriental mt-10 lg:mt-16">
          <div className="p-5 xl:max-w-7xl xl:mx-auto xl:px-0">
            <p className="lg:w-1/2">
              The unique Megauae Account allows our clients to conduct trades
              perfectly protects them out of slow transaction times or
              information and payments errors.
            </p>

            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:gap-10 md:items-center">
              <article>
                <h3 className="text-xl mb-3 mt-5">Advantages:</h3>
                <p className="mb-8">
                  There are few more additional benefits that are shaped exactly
                  to satisfy our client’s needs. You can be sure in:
                </p>

                <ul className="text-left">
                  <li className="flex items-center mb-5">
                    <img
                      src={tick}
                      alt="checkmark"
                      title="Check mark"
                      className="mr-2"
                    />{" "}
                    100% client oriented experience
                  </li>
                  <li className="flex items-center mb-5">
                    <img
                      src={tick}
                      alt="checkmark"
                      title="Check mark"
                      className="mr-2"
                    />{" "}
                    24/5 client support and help in dealing with the most common
                    issues
                  </li>
                  <li className="flex items-center mb-5">
                    <img
                      src={tick}
                      alt="checkmark"
                      title="Check mark"
                      className="mr-2"
                    />{" "}
                    Effective use of your capital
                  </li>
                  <li className="flex items-center mb-5">
                    <img
                      src={tick}
                      alt="checkmark"
                      title="Check mark"
                      className="mr-2"
                    />{" "}
                    128 bit SSL Security Protocols
                  </li>
                  <li className="flex items-center mb-5">
                    <img
                      src={tick}
                      alt="checkmark"
                      title="Check mark"
                      className="mr-2"
                    />{" "}
                    Convenient and client-friendly management of your funds
                  </li>
                </ul>

                <h3 className="text-xl mb-3 mt-10">
                  When will a Senior Broker be assigned to me?
                </h3>
                <p>
                  Your Personal Senior Broker will be assigned to you upon
                  activating and funding your account.
                </p>
              </article>

              <article>
                <img
                  src={segAccounts}
                  alt="Segregated Accounts"
                  title="Segregated Accounts"
                  className="block mx-auto w-full"
                />
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
