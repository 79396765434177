import OpenAccount from "../buttons/OpenAccount"
import OpenAccountLight from "../buttons/OpenAccountLight"
import { v4 as uuidv4 } from "uuid"
import creditCard from "../images/credit-card.svg"
import bank from "../images/bank.svg"
import wireTransfer from "../images/wire-transfer.svg"

export const navbar = [
  {
    id: uuidv4(),
    title: "Account",
    url: "/trading-accounts",
    subLinks: [
      {
        id: uuidv4(),
        title: "Trading Accounts",
        url: "/trading-accounts",
      },
      {
        id: uuidv4(),
        title: "Account Funding",
        url: "/trading-accounts#account-funding",
      },
      {
        id: uuidv4(),
        title: "Segregated Accounts",
        url: "/trading-accounts#segregated-accounts",
      },
      {
        id: uuidv4(),
        title: "Account FAQs",
        url: "/trading-accounts#faqs",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "About",
    url: "/about-us",
    subLinks: [
      {
        id: uuidv4(),
        title: "About Us",
        url: "/about-us",
      },
      {
        id: uuidv4(),
        title: "Contact Us",
        url: "/about-us#contact-us",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Platforms",
    url: "/platforms",
    subLinks: [
      {
        id: uuidv4(),
        title: "Web Trader",
        url: "/platforms#web-trader",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "Resources",
    url: "/resources",
    subLinks: [
      {
        id: uuidv4(),
        title: "Terms and Conditions",
        url: "/terms-and-conditions",
      },
      {
        id: uuidv4(),
        title: "Privacy Policy",
        url: "/privacy-policy",
      },
    ],
  },
]

export const trading = [
  {
    id: uuidv4(),
    mobileImage: "./images/mobile-image-1.jpg",
    desktopImage: "./images/desktop-1.jpg",
    title: "INTUITIVE TOOLS AND COMPETITIVE PRICING",
    desc: "Whether you’re an active trader or long-term investor our web, mobile, and downloadable platforms give you everything you need to research stocks, place trades, and manage your portfolio with live-streaming access, real-time quotes and specialists with years of floor trading experience. As an STP (Straight Through Processing) broker, we do not have any conflict of interest regarding our clients’ trades, as we only receive a commission on the volume of the trades and do not benefit in any additional way by any client losses.",
  },
  {
    id: uuidv4(),
    mobileImage: "./images/mobile-image-2.jpg",
    desktopImage: "./images/desktop-2.jpg",
    title: "TRANSPARENT AND TRUSTWORTHY",
    desc: "For a few years now, Megauae has been proud to help our clients pursue their financial goals while giving them more time to focus on what really matters in life. Everything we provide, from guidance to powerful trading tools, to retirement resources is built around one thing, you. We know it's important to get professional guidance you can trust. That's why our experienced, licensed reps are available by phone, email, online, and in person for one-on-one support when you need it most. You can always count on us.",
  },
  {
    id: uuidv4(),
    mobileImage: "./images/mobile-image-3.jpg",
    desktopImage: "./images/desktop-3.jpg",
    title: "SECURED FUNDS",
    desc: "Your funds are kept in segregated 3rd party regulated financial institutions and we use advanced encryption tools to keep all of your transactions and personal data safe and secured. Also our flat-rate commission on all online equity trades gives you access to our award-winning investing and trading experience. Plus, we keep our pricing simple with no share limits or hidden fees, no trade requirements to access advanced features, and no deposit minimums. At Megauae all transactions are simple and direct.",
  },
  {
    id: uuidv4(),
    mobileImage: "./images/mobile-image-4.jpg",
    desktopImage: "./images/desktop-4.jpg",
    title: "ALGO TRADING",
    desc: "Algorithmic Trading is a program that offers everyone the opportunity to trade with better precision and high success rate than he/she would normally get. It makes use of a scenario-based approach and artificial intelligence so as to develop the best trading approach for the circumstances. Different variables such as price, quantity and time period are taken into serious consideration as well. The number of traders that use our Algorithmic trading keeps increasing by the day, as it makes the trading journey easy and well-profiting.",
  },
]

export const tradingAccounts = [
  {
    id: uuidv4(),
    title: "Mini",
    price: "$ 500",
    desc: "Basic Trading For Beginners",
    items: [
      "Online Chat Support",
      "Platforms: MT4 & Mobile",
      "Instruments: Forex, CFDs, & Commodities",
      "Free E-book",
      "Individual Competitive Spreads & Commissions",
      "Access to Breaking News & Market Research",
    ],
    button: <OpenAccountLight />,
  },
  {
    id: uuidv4(),
    title: "Classic",
    price: "$ 5 000",
    desc: "For Skilled Investors",
    items: [
      "Online Chat Support",
      "Platforms: MT4 & Mobile",
      "Instruments: Forex, CFDs, & Commodities",
      "Free E-book",
      "Individual Competitive Spreads & Commissions",
      "Access to Breaking News & Market Research",
      "Online Phone Support",
      "Real-Time Trading Signals",
      "Trading Education Program",
    ],
    button: <OpenAccount />,
  },
  {
    id: uuidv4(),
    title: "Classic",
    price: "$ 20 000",
    desc: "Advanced Trading For Experienced Investors",
    items: [
      "Online Chat Support",
      "Platforms: MT4 & Mobile",
      "Instruments: Forex, CFDs, & Commodities",
      "Free E-book",
      "Individual Competitive Spreads & Commissions",
      "Access to Breaking News & Market Research",
      "Online Phone Support",
      "Real-Time Trading Signals",
      "Trading Education Program",
      "Account Executive Team",
      "Available 24/7",
      "Trade Mentor",
    ],
    button: <OpenAccountLight />,
  },
]

export const steps = [
  {
    id: uuidv4(),
    number: 1,
    title: "Pick the account types depending on your objectives and needs",
    desc: "Three available account types are supplemented with lots of additional features that bring some extra pluses to the investor. You can easily sign up for any of them. And keep the notice that you can change the account type after putting money on your account.",
  },
  {
    id: uuidv4(),
    number: 2,
    title: "Fill out the demanded information and confirm your identity",
    desc: "You just need to complete information profile with your personal data and to provide some identity papers.",
  },
  {
    id: uuidv4(),
    number: 3,
    title: "Depositing money into your account",
    desc: "Once we approve and authorize your application, you will be able to make your payments and withdrawals swiftly.",
  },
]

export const funding = [
  {
    id: uuidv4(),
    image: creditCard,
    title: "Credit Card / Debit Card",
    desc: "The best way to fund your account is with a credit or debit card. The transfer is immediate and secure. Minimum transaction amount $250 with a maximum amount $500. Please not your card should be enabled for international transactions.",
  },
  {
    id: uuidv4(),
    image: bank,
    title: "Bank Draft",
    desc: "You can utilize your banks services and transfer funds using a bank draft. Please note that in some cases it might take up to 5 days for your funds to clear.",
  },
  {
    id: uuidv4(),
    image: wireTransfer,
    title: "Wire Transfer",
    desc: "Please use the details provided by your Senior Broker. The minimum amount is $10.000 for bank wires. If you have online banking you can input the details provided online, or visit your bank and fill in the wire transfer form. Please remember to provide your Senior Broker the remittance slip. Depending on your bank your funds might take up to 5 days to clear. As soon as the funds are in your trading account you will receive an email notification.",
  },
]

export const faqs = [
  {
    id: uuidv4(),
    question: "How to open a Megauae trade account trade account?",
    answer1:
      "It is easy and secure to open a Megauae account. Initially, you can choose to trade on a free demo account. However, if you're ready to trade, you can start trading on your Live Account. Based on how you would like to fund your account, there are three types of accounts available; once you begin trading there is always the option to upgrade from one account type to another.",
    answer2:
      "You will need to fill out a short registration form for any Live Account that you want to open. Legal documentation is required by regulation in order to provide proof of your identity. As soon as your identity has been confirmed, all you need to do is download the platform you want to trade on, make a deposit to fund your account, and then you're ready to start trading.",
  },
  {
    id: uuidv4(),
    question: "Which types of accounts are available?",
    answer1: `Three kinds of accounts are offered by Megauae:
    Mini Account - minimum deposit at $500 USD.
    Classic Account - minimum deposit at $5000 USD.
    Premium Account - minimum deposit at $20000 USD.`,
  },
  {
    id: uuidv4(),
    question: "Which platform should you use?",
    answer1:
      " Megauae MetaTrader 4 - Download this popular, world-renowned Forex desktop platform if you predominately trade in foreign currencies. It is both user-friendly and state-of-the-art.",
    answer2:
      "Megauae Mobile - this solution provides apps for Android, tablets, and iPhone, enabling traders to trade from anywhere in the world, from a variety of devices.",
  },
  {
    id: uuidv4(),
    question: "What type of information do you need to give in order to trade?",
    answer1:
      "The stop-loss order and the limit order are the most common risk management tools in Forex trading. A limit order sets a restriction on the minimum price to be received or the maximum price to be paid.",
    answer2:
      "An automatic liquidation at a predetermined price for a particular position is called a stop loss. This is used to limit potential losses if the market moves against an investor’s position.",
  },
  {
    id: uuidv4(),
    question: "How do you manage your risk?",
    answer1:
      "You are requested to give the following information for proof of identity upon registration: 1. Proof of ID. A copy of your Passport, National ID or Driver’s License 2. A copy of a utility bill or bank statement, not older than 3 months",
    answer2:
      "You will need to clearly indicate the beneficiary name on the Megauae- Client Agreement. It is important that the account beneficiary name corresponds to the name on the client agreement form, and also the documents for proof of identity. In addition, if you wish to withdraw funds, it must be to the same beneficiary.",
  },
]
