import { useState } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"

export default function SingleQuestion({ question, answer1, answer2 }) {
  const [showAnswer, setShowAnswer] = useState(false)

  return (
    <>
      <div className="faqs flex items-center justify-between text-left border-b border-gray-800 bg-gray-900 p-5">
        <article>
          <h3
            onClick={() => setShowAnswer(!showAnswer)}
            className="cursor-pointer text-xl mb-2"
          >
            {question}
          </h3>
          {showAnswer && (
            <div>
              <p className="mb-0">{answer1}</p>
              <p className="mt-5 mb-0">{answer2}</p>
            </div>
          )}
        </article>

        <button
          onClick={() => setShowAnswer(!showAnswer)}
          className="p-0 border-none shadow-none"
        >
          {showAnswer ? (
            <FaChevronUp className="text-yellow-200" />
          ) : (
            <FaChevronDown className="text-yellow-200" />
          )}
        </button>
      </div>
    </>
  )
}
