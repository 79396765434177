import { useState } from "react"
import { Footer } from "../components"
import { tradingAccounts } from "../data/data"
import tick from "../images/tick.svg"
import SegregatedAccounts from "./components/SegregatedAccounts"
import Steps from "./components/Steps"
import AccountFunding from "./components/AccountFunding"
import AccountFaqs from "./components/AccountFaqs"

export default function TradingAccounts() {
  const [cards] = useState(tradingAccounts)

  return (
    <>
      <section className="py-10 lg:py-20">
        <h1 className="text-center uppercase px-5 xl:px-0">Trading Accounts</h1>
        <p className="text-center md:w-1/2 md:mx-auto px-5 xl:px-0 mb-10">
          Our accounts provide benefits of Forex market to private and corporate
          clients, using one of the most advanced trading platforms available.
        </p>

        <div className="trading-accounts grid grid-cols-1 gap-5 md:grid-cols-2 md:items-start lg:grid-cols-3 xl:max-w-7xl xl:mx-auto px-5">
          {cards.map(({ id, title, price, desc, items, button }) => (
            <article key={id} className="pt-5 px-5 pb-10 rounded">
              <h3 className="uppercase text-center text-3xl tracking-wider mb-3">
                {title}
              </h3>
              <h4 className="text-center text-5xl font-extralight text-white mb-5">
                {price}
              </h4>
              <p className="text-center mb-5">{desc}</p>

              <ul className="text-left">
                {items.map((item, index) => (
                  <li key={index} className="flex items-center mb-8">
                    <img src={tick} alt="checkmark" className="mr-2" /> {item}
                  </li>
                ))}
              </ul>

              <div className="">{button}</div>
            </article>
          ))}
        </div>

        <div className="py-10">
          <Steps />
        </div>

        <div>
          <SegregatedAccounts />
        </div>

        <div>
          <AccountFunding />
        </div>

        <div>
          <AccountFaqs />
        </div>
      </section>

      <Footer />
    </>
  )
}
