import { useState } from "react"
import { funding } from "../../data/data"

export default function AccountFunding() {
  const [cards] = useState(funding)

  return (
    <>
      <section className="lg:relative" id="account-funding">
        <h2 className="lg:absolute right-0 lg:rounded-l-full lg:-top-24 lg:w-1/2 lg:px-5">
          Account Funding
        </h2>
        <div className="underline"></div>

        <div className="trade-oriental py-10 lg:mt-32 px-5 xl:px-0">
          <h3 className="text-2xl text-center mb-3">
            There are 3 different ways to fund your Megauae account
          </h3>
          <div className="underline-header mb-10 mx-auto"></div>

          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 xl:max-w-7xl xl:mx-auto">
            {cards.map(({ id, image, title, desc }) => (
              <article key={id} className="text-center">
                <img
                  src={image}
                  alt={title}
                  title={title}
                  className="block mx-auto w-10"
                />
                <h3 className="mt-5 mb-3">{title}</h3>
                <p>{desc}</p>
              </article>
            ))}
          </div>
          <div className="mt-10 px-5 xl:px-0 xl:max-w-7xl xl:mx-auto">
            <article className="lg:w-2/3">
              <h3 className="text-xl mb-3">Withdrawing Funds</h3>
              <div className="underline-header mb-5"></div>
              <p>In order to submit a withdrawal request you need to:</p>

              <p>
                Log in to your account in your client area and submit a
                withdrawal request.
              </p>

              <p>
                Your request will be addressed and processed promptly. Usually,
                provided the account is verified the request is process within
                the next 2 business days. Depending on your bank, your funds
                might take longer to appear in your bank account.
              </p>
            </article>

            <article className="lg:w-2/3 mt-10">
              <h3 className="text-xl mb-3">
                Things you should take into account:
              </h3>
              <div className="underline-header mb-5"></div>
              <p>
                The initial amount invested will be returned through the same
                method deposited. If the requested amount exceeds the initial
                invested amount, you need to provide us with the bank account
                details of an account under your name, in order to receive the
                excess amount.
              </p>

              <p>
                For further inquiries regarding funding please contact us on
                support@megauae.pro or through our live chat.
              </p>

              <p>
                In case you would like to ask anything related to putting money
                on the Live Account, feel free to get in touch with a Megauae
                employee.
              </p>
            </article>
          </div>
        </div>
      </section>
    </>
  )
}
