import { useState } from "react"
import { steps } from "../../data/data"

export default function Steps() {
  const [cards] = useState(steps)

  return (
    <>
      <section className="trading-account py-10">
        <h3 className="text-center mb-5 text-xl font-light">
          Steps for opening a Megauae Trading Profile{" "}
          <span className="block">Opening a trading account at Megauae</span>
        </h3>
        <div className="underline-header mx-auto mb-10"></div>

        <div className="grid grid-cols-1 gap-5 px-5 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 xl:px-0 xl:max-w-7xl xl:mx-auto">
          {cards.map(({ id, number, title, desc }) => (
            <article key={id}>
              <h3 className="flex items-center mb-5">
                <span className="text-5xl mr-2 lg:text-7xl">{number}</span>{" "}
                {title}
              </h3>
              <div className="underline-green"></div>
              <p className="mt-5">{desc}</p>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
