import React, { useState } from "react"
import { Link } from "react-router-dom"
import { navbar } from "../data/data"

export default function Footer() {
  const [items] = useState(navbar)

  return (
    <>
      <footer className="footer px-5 py-10 lg:px-0">
        <div className="xl:max-w-7xl xl:mx-auto xl:px-0">
          <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
            {items.map(({ id, title, url, subLinks }) => (
              <React.Fragment key={id}>
                <div>
                  <li className="mb-2">
                    <Link to={url} className="text-white font-bold text-xl">
                      {title}
                    </Link>
                  </li>
                  <div className="pl-5">
                    {subLinks.map((link) => (
                      <li key={link.id} className="text-sm my-3">
                        <Link to={link.url}>{link.title}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </ul>

          <div>
            <h3 className="mt-10 mb-3 text-2xl">RISK WARNING</h3>
            <p>
              Trading in Forex and CFDs carries a high level of risk and can
              result in the loss of part or all of your investment. Trading in
              Forex and CFDs may not be appropriate for all investors. You
              should be aware of all the risks associated with Forex and CFD
              trading and seek for independent advice. Past performance does not
              constitute a reliable indication of future results. Forecasts for
              the future do not constitute a reliable indicator of future
              performance. Copyright for the pages and for the screens
              displaying the pages, and for the information, material and their
              arrangement, is owned by Megauae all rights reserved.
            </p>

            <p>
              This information is intended for investors outside the United
              States who are not US/Japanese citizens and residents. This
              website is intended for informational purposes only. This website
              is not directed at any jurisdiction and is not intended for any
              use that would be contrary to local law or regulation. The
              products described on this are not offered and may not be sold in
              the United States/Japan or to US/Japanese citizens and residents.
            </p>
          </div>
        </div>
        <div className="bg-white py-2 mt-10">
          <p className="text-gray-800 font-bold text-center mb-0">
            Copyright 2024 All Rights Reserved Megauae
          </p>
        </div>
      </footer>
    </>
  )
}
