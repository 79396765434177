import tradeOrientalMobile from "../images/mobile-trade-oriental.jpg"
import tradeOrientalDesktop from "../images/desktop-trade-oriental.jpg"

export default function TradeOriental() {
  return (
    <>
      <section className="trade-oriental lg:relative px-5 py-10 lg:pt-32 mb-10 lg:mb-20">
        <h2 className="lg:absolute right-0 lg:rounded-l-full lg:top-20 lg:w-1/2 lg:px-5">
          Trade Oriented Broker
        </h2>
        <div className="underline"></div>
        <div className="xl:max-w-7xl xl:mx-auto">
          <article className="lg:grid grid-cols-2 gap-5 lg:gap-10 items-center mt-10">
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet={tradeOrientalDesktop}
              />
              <img
                src={tradeOrientalMobile}
                alt="Trade Oriental Broker"
                title="Trade Oriental Broker"
                className="w-full"
              />
            </picture>

            <div className="px-5 lg:px-0">
              <p className="mt-5">
                Megauae is a top online Forex broker with many years of
                experience in the market. Megauae being a client-oriented
                company, places the needs of every one of our customers as its
                own priority. We are committed to providing all our investors a
                wide range of options when trading to help them become better at
                trading and also make profits.
              </p>
              <p>
                At Megauae we ensure all our clients are up to date with the
                market trends and also provide them with latest trading
                technology and Forex tools by investing continuously in
                innovations. These instruments are updated on a regular basis
                thereby offering new platforms and services to answer the
                various questions and meet the need of every trader, in so
                doing; we are keeping our promise and commitment towards a fair
                and open trading.
              </p>
              <p>
                All our trusted clients have a 24/7 access to our various
                comprehensive educational materials. These materials help
                trader's understand and learn more about the various online
                operations, trading and the global market in general. We have a
                great customer support team which is always and readily
                available to assist with any issue you might encounter or
                challenge you might face in the course of trading.
              </p>
              <p>
                At Megauae we place high importance and emphasis on customer
                satisfaction, and we are dedicated to being more than just an
                online broker to our clients. Make your trading experience worth
                it, choose a right broker, and choose Megauae!
              </p>
            </div>
          </article>
        </div>
      </section>
    </>
  )
}
