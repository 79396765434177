import { Link } from "react-router-dom"
import { Footer } from "../components"

export default function Error() {
  return (
    <>
      <section className="flex flex-col items-center justify-center h-screen">
        <h1 className="font-light text-xl mb-5">
          The page you are looking for does not exist or may have moved
        </h1>
        <button className="btn-primary">
          <Link to="/">Back to Homepage</Link>
        </button>
      </section>
      <Footer />
    </>
  )
}
