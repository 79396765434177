import webTrader from "../images/web-trader.jpg"
import tick from "../images/tick.svg"
import { Footer } from "../components"

export default function Platforms() {
  return (
    <>
      <section>
        <h1 className="text-center mb-10 lg:mt-10 uppercase">Platforms</h1>

        <div className="xl:max-w-7xl xl:mx-auto" id="web-trader">
          <h2 className="lg:absolute lg:left-0 lg:rounded-r-full lg:px-5 lg:w-1/2 lg:text-center">
            Web Trader
          </h2>
          <div className="underline"></div>
          <article>
            <p className="px-5 lg:pt-24 lg:w-2/3">
              Megauae highest purpose is to turn its clients into successful
              traders and increase their success rates as much as possible. For
              that purpose we give you the Megauae Online trader, which
              enables you to be 100% involved in the trading process and adjust
              according to the market conditions, all the while letting you plan
              your trading activities to your preference.
            </p>
            <p className="px-5 pb-5 lg:w-2/3">
              Utilize the full scope of the latest technological developments in
              the field by trading from any internet connectible device.
              Investing in Forex, Commodities and CFD has never been easier
              thanks to the Megauae Online Trader.
            </p>
          </article>
        </div>

        <div className="trade-oriental mb-10 xl:mb-20">
          <div className="px-5 xl:px-0 grid grid-cols-1 gap-5 md:grid-cols-2 lg:gap-10 py-10 lg:py-20 items-center xl:max-w-7xl xl:mx-auto">
            <article>
              <img
                src={webTrader}
                alt="Web Trader"
                title="Web Trader"
                className="block mx-auto w-full bg-white p-3"
              />
            </article>

            <article>
              <h3 className="text-2xl mb-5">
                Some of the other pros of the platform are:
              </h3>
              <ul>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Instant Execution.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Automated Trading.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Secure Application.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Advanced charts availability.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Slippage protection.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  3rd party bridging.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Availability of four pending orders.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Buy Limit, But Stop, Sell Limit, Sell Stop.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  0.01 lot sizes.
                </li>
                <li className="flex items-center mb-5">
                  <img src={tick} alt="tick" className="mr-2" />
                  Partial closing and many others.
                </li>
                <li>
                  <button onClick={() => window.location.href = 'https://client.megauae.pro/'} className="btn-primary">Web Trader</button>
                </li>
              </ul>
            </article>
          </div>
        </div>

        <Footer />
      </section>
    </>
  )
}
