export default function GetStarted() {
  return (
    <>
      <section className="lg:relative py-10 px-5 text-left lg:py-32">
        <h2 className="text-center lg:absolute left-0 top-20 lg:px-10 lg:rounded-r-full lg:w-1/2 uppercase">
          Get Started with us
        </h2>
        <div className="underline"></div>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 lg:mt-20 xl:max-w-7xl xl:mx-auto">
          <article className="trade-oriental pt-3 px-3 rounded-lg">
            <h3 className="flex items-center uppercase text-xl tracking-wider">
              <span className="text-4xl mr-3">1</span>Register
            </h3>
            <p className="pl-9">Open your Megauae Trading Account</p>
          </article>

          <article className="trade-oriental pt-3 px-3 rounded-lg">
            <h3 className="flex items-center uppercase text-xl tracking-wider">
              <span className="text-4xl mr-3">2</span>Verify
            </h3>
            <p className="pl-9">Verify your documents with our Compliance</p>
          </article>

          <article className="trade-oriental pt-3 px-3 rounded-lg">
            <h3 className="flex items-center uppercase text-xl tracking-wider">
              <span className="text-4xl mr-3">3</span>Fund
            </h3>
            <p className="pl-9">
              Deposit funds into your Megauae trading account
            </p>
          </article>

          <article className="trade-oriental pt-3 px-3 rounded-lg">
            <h3 className="flex items-center uppercase text-xl tracking-wider">
              <span className="text-4xl mr-3">4</span>Trade
            </h3>
            <p className="pl-9">
              Start trading Forex, Indices, Stocks and Commodities
            </p>
          </article>
        </div>
      </section>
    </>
  )
}
