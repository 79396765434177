export default function Innovative() {
  return (
    <>
      <section className="innovative">
        <div className="overlay flex items-center justify-center flex-col text-center px-5">
          <h3 className="text-3xl mb-5 leading-normal font-light">
            TAKE ON THE MARKET WITH OUR INNOVATIVE POWERFUL PLATFORMS
          </h3>
          <p>
            Trade Shares, Indices, Forex, Commodities and Cryptocurrencies with
            CFDs
          </p>
          <button className="btn-white font-bold">Try It For Free</button>
        </div>
      </section>
    </>
  )
}
