import OpenAccount from "../buttons/OpenAccount"
import mobileHero from "../images/mobile-hero.jpg"
import desktopHero from "../images/desktop-hero.jpg"

export default function Hero() {
  return (
    <>
      <section className="px-5 flex flex-col-reverse items-center md:grid grid-cols-2 gap-10 md:items-center md:pt-20 xl:max-w-7xl xl:mx-auto">
        <article>
          <h1 className="mb-5">
            Trade CFDs on Forex, Commodities, Indices, Shares &amp; Crypto
          </h1>
          <OpenAccount />
        </article>

        <article className="mb-5 md:mb-0">
          <picture>
            <source media="(min-width: 768px)" srcSet={desktopHero} />
            <img src={mobileHero} alt="" className="block mx-auto" />
          </picture>
        </article>
      </section>
    </>
  )
}
