import React, { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { navbar } from "../data/data"
import LogIn from "../buttons/LogIn"
import OpenAccount from "../buttons/OpenAccount"
import { CgClose, CgMenuLeft } from "react-icons/cg"
import Logo from '../images/logo.png'

export default function Header() {
  const [items] = useState(navbar)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenMenu = () => {
    const navbar = document.querySelector(".navbar")
    const navItem = document.querySelectorAll(".nav-item")

    navbar.classList.toggle("open")

    navItem.forEach((item) =>
      item.addEventListener("click", () => {
        navbar.classList.remove("open")
      })
    )
  }

  return (
    <>
      <header className="relative p-5 flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold uppercase tracking-wider bg-transparent text-white">
            <Link to="/"><img src={Logo} alt='logo' /></Link>
          </h2>
        </div>

        <nav className="navbar bg-gray-800 p-5 pb-8 lg:p-5 rounded-lg lg:rounded-full lg:bg-white">
          <ul className="flex flex-col lg:flex-row items-start justify-start lg:items-center">
            {items.map(({ id, title, url }) => (
              <React.Fragment key={id}>
                <li className="nav-item mb-5 text-white lg:text-gray-800 lg:ml-10 lg:mb-0">
                  <NavLink to={url} onClick={() => setIsOpen(false)}>
                    {title}
                  </NavLink>
                </li>
              </React.Fragment>
            ))}

            {isOpen && (
              <div className="absolute top-24 w-full left-0">
                <div className="hidden lg:block">
                  <div className="grid grid-cols-4 bg-white rounded">
                    {items.map(({ title, subLinks }) => (
                      <div className="p-5">
                        <h3 className="text-gray-800 text-2xl uppercase">
                          {title}
                        </h3>
                        <div className="underline-header mt-3 mb-5"></div>
                        {subLinks.map((link) => (
                          <article
                            key={link.id}
                            className="z-50 my-5 -left-20 top-14"
                          >
                            <ul>
                              <li className="text-gray-800">
                                <Link
                                  to={link.url}
                                  onClick={() => setIsOpen(false)}
                                >
                                  {link.title}
                                </Link>
                              </li>
                            </ul>
                          </article>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <li className="mb-5 lg:mx-8 lg:mb-0">
              <LogIn />
            </li>
            <li>
              <OpenAccount />
            </li>

            {/* Jumbo menu */}
            <div className="hidden lg:block ml-10">
              <button
                className="p-0 shadow-none"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <CgClose className="text-4xl text-gray-800" />
                ) : (
                  <CgMenuLeft className="text-4xl text-gray-800" />
                )}
              </button>
            </div>
          </ul>
        </nav>

        <div className="absolute right-5 z-50 lg:hidden">
          <button
            onClick={handleOpenMenu}
            className="menu-button p-0 shadow-none"
          >
            <CgMenuLeft className="text-4xl text-white" />
          </button>
        </div>
      </header>
    </>
  )
}
