import { Link } from "react-router-dom"

export default function OpenAccount() {
  return (
    <>
      <a href="https://client.megauae.pro/register">
        <button className="btn-primary">Open Account</button>
      </a>
    </>
  )
}
