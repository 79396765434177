import { Link } from "react-router-dom"

export default function OpenAccountLight() {
  return (
    <>
      <a href="https://client.megauae.pro/register">
        <button className="btn-white">Open Account</button>
      </a>
    </>
  )
}
