import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import {
  Footer,
  GetStarted,
  Header,
  Hero,
  Innovative,
  ScrollToTop,
  TradeOriental,
  Trading,
} from "./components"
import {
  AboutUs,
  Platforms,
  TradingAccounts,
  Error,
  TermsAndConditions,
  PrivacyPolicy,
} from "./pages"

export default function App() {
  return (
    <Router>
      <main>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <Hero />
                <Trading />
                <TradeOriental />
                <Innovative />
                <GetStarted />
              </React.Fragment>
            }
          ></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/platforms" element={<Platforms />}></Route>
          <Route path="/trading-accounts" element={<TradingAccounts />}></Route>
          <Route path="/resources" element={<TermsAndConditions />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
        <Footer />
      </main>
    </Router>
  )
}
