import { useState } from "react"
import { trading } from "../data/data"

export default function Trading() {
  const [cards] = useState(trading)

  return (
    <>
      <section className="py-10 px-5 lg:relative lg:px-0 lg:py-20">
        <h2 className="lg:absolute lg:left-0 lg:rounded-r-full lg:px-5 lg:w-1/2 lg:text-center">
          Megauae Trading
        </h2>
        <div className="underline"></div>
        <article className="xl:max-w-7xl xl:mx-auto">
          <p className="mb-10 lg:mt-20 md:w-11/12 md:mx-auto lg:ml-0 lg:w-1/2 px-5">
            Your goals are unique, so your investment guidance should be too.
            This is why at Megauae we take the time to listen and understand
            what matters most to you before helping you develop and manage your
            investment strategy.
          </p>

          <div className="trading grid grid-cols-1 gap-10 lg:gap-20 px-5">
            {cards.map(({ id, mobileImage, desktopImage, title, desc }) => (
              <article key={id} className="md:flex items-center">
                <picture>
                  <source media="(min-width: 1024px)" srcSet={desktopImage} />
                  <img
                    src={mobileImage}
                    alt={title}
                    title={title}
                    className="w-full mx-auto"
                  />
                </picture>
                <div className="p-5 md:w-1/2 lg:p-0">
                  <h3 className="mt-3 mb-4 md:text-xl">{title}</h3>
                  <p>{desc}</p>
                </div>
              </article>
            ))}
          </div>
        </article>
      </section>
    </>
  )
}
