export default function PrivacyPolicy() {
  return (
    <>
      <section className="px-5 xl:px-0 py-20">
        <h2 className="text-center text-white text-3xl uppercase px-5 xl:px-0 bg-transparent lg:text-6xl">
          Privacy Policy
        </h2>
        <div className="underline-header mx-auto mt-3 mb-5"></div>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:gap-10 xl:max-w-7xl xl:mx-auto">
          <div>
            <article>
              <h3 className="text-white text-xl mb-2">
                1. Private Information
              </h3>
              <p>
                At the time activating of your profile at Megauae, we would
                request that you share the necessary personal info, in order to
                provide you the optimum trading experience. We need the info to
                make the necessary transfers and trades, to determine your
                financial scope, to handle any forgotten password issues, make
                customer care easier and let you know about any Forex related
                matter that might concert you. We are also obliged by the law to
                keep proper records.
              </p>
            </article>
            <article>
              <h3 className="text-white text-xl mb-2">
                2. We might request that you share the following thing with us:
              </h3>
              <p>
                In order to apply: your name, DOB, a form of identification or
                social security, proper address. We might also need to make sure
                of your occupation, financial status, and more relevant
                information, as described during the process of creating a
                profile.
              </p>
              <p>
                In order to make transfer funds – statements or copies of
                previous correspondence and details about your transfers with No
                Megauae or its partners, along with any trading actions,
                financial statements, previously asked questions and the
                respective answers.
              </p>
              <p>
                For authentication purposes – we might need certain documents
                for making sure that the information represents the real person
                (some form of personal identification) and also bank statements,
                utility statements, bank card information and so on.
              </p>
              <p>
                Attention: Megauae is obliged by the AML and the USA Patriot
                Act to gather any data if there is a need and act accordingly to
                verify a user`s identification.
              </p>
              <p>
                Attention: Megauae wants to clarify that it does not
                purposefully keep or gather any information of any individuals
                under the age of thirteen.
              </p>
              <p>
                If it comes to the knowledge of Megauae that information has
                been collected from a person under the age of thirteen, then the
                appropriate measures will be taken to erase that information.
              </p>
              <p>
                Please, if any parents or attendants of children learn that
                their child has submitted private/personal information with no
                permission, write us immediately.
              </p>
            </article>
          </div>

          <div>
            <article>
              <h3 className="text-white text-xl mb-2">
                3. Utilization of cookies
              </h3>
              <p>
                Some information is being transmitted from the platform`s server
                to the user`s browser and kept on the user`s device. This is
                referred to as cookies. Megauae gathers necessary data by
                using the cookies. Don`t worry, as cookies don`t have any
                private information regarding the user in them, with the
                exception when the user has provided such information by himself
                – like when opening a profile at a website. Megauae will
                utilize the cookies for the purpose of identifying and
                quantifying platform traffic, along with measuring how certain
                services and ads are performing.
              </p>
              <p>
                This way Megauae can better gauge the ways in which the users
                are utilizing the platform, and make enhancements to the
                performance of the website. Additionality, it lets us know what
                kind of relevant materials and information we should provide to
                you.
              </p>
              <p>
                The collected data might also be made available to Megauae
                staff, partners, and associates. Megauae will not use the
                information for commercial purposes by providing it to any
                unassociated individuals or entities.
              </p>
              <p>
                When the user goes to Megauae, there might be other affiliated
                parties that also place their cookies on the user`s computer. We
                would advise you to take a look at the terms and conditions of
                those affiliate parties` websites in case you have any questions
                or issues regarding their placement of cookies. Usually, the
                usage of cookies is the same as on Megauae.
              </p>
            </article>
            <article>
              <h3 className="text-white text-xl mb-2">
                4. Disclosure information.
              </h3>
              <p>
                Some information might be disclosed fully or partially with our
                trusted partners. The purpose of this is to be able to let
                customers know of any new offers or functionalities, along with
                providing assistance with user profiles. The partners consist of
                partiers related to the platform, who are usually stakeholders
                of some kind. The confidentiality of your private data will be
                kept by our partners as it is being kept by Megauae, following
                the privacy terms and conditions stated on the platform.
              </p>
            </article>
          </div>
        </div>
        <button className="uppercase text-xl text-white block mx-auto border-2 border-yellow-200">
          Read More...
        </button>
      </section>
    </>
  )
}
