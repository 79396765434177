import { Link } from "react-router-dom"

export default function LogIn() {
  return (
    <>
      <a href="https://client.megauae.pro/">
        <button className="btn-blue">Log In</button>
      </a>
    </>
  )
}
