import aboutUs from "../images/about-us.jpg"
import contactUs from "../images/contact-us.jpg"
import email from "../images/email.svg"
import clock from "../images/clock.svg"
import phone from "../images/phone.svg"
import Footer from "../components/Footer"

export default function AboutUs() {
  return (
    <>
      <section className="px-5 xl:px-0">
        <h1 className="text-center mb-10 lg:mt-10 uppercase">About Us</h1>

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-10 items-center xl:max-w-7xl xl:mx-auto pb-10 lg:pb-0">
          <article>
            <p>
              Megauae was launched a few years back with a unique vision to
              provide unparalleled advanced trading conditions, advanced
              education, and state-of-the-art trading tools in the forex
              industry. Due to the continuous focus on localizing our products
              and services to suit each market, together with our successful
              collaboration with talented and experienced teams from all over
              the world. Megauae was quickly established as the global
              trademark it is today.
            </p>

            <p>
              Our number one mission is centered on time. By valuing time and
              understanding the importance of efficiency in the fluctuating,
              lightning-fast world of financial markets, every decision we make
              is designed to ensure that our clients and business partners, as
              well as our team of professionals, invest their time wisely.{" "}
            </p>
            <p>
              This road is paved by an ironclad focus on educating our
              customers. Knowledge is power, and empowering traders to get the
              best value for their investments is our passion.At Megauae we
              are committed to making sure all our clients and investors stay
              connected to the various opportunities that arise in the market on
              a daily basis and ease in connecting with one another. With No
              Megauae has your financial stockbroker you are sure to get all the
              professional backing and support you require to soar in Forex
              trading.
            </p>
          </article>

          <article>
            <img
              src={aboutUs}
              alt="about us"
              title="About Us"
              className="block mx-auto w-full"
            />
          </article>
        </div>

        <div className="pb-10 lg:pb-20 lg:relative" id="contact-us">
          <h2 className="lg:absolute right-0 top-20 lg:px-10 lg:rounded-l-full lg:w-1/2">
            Contact Us
          </h2>
          <div className="underline"></div>

          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:max-w-7xl xl:mx-auto lg:gap-10 items-center lg:pt-52">
            <article>
              <img
                src={contactUs}
                alt="contact us"
                title="Contact Us"
                className="block mx-auto w-full"
              />
            </article>

            <article>
              <ul>
                <li className="flex items-center mb-5 lg:mb-10">
                  <img
                    src={email}
                    alt="email"
                    title="Email us"
                    className="w-10"
                  />
                  <p className="mb-0 ml-5">
                    Support email:{" "}
                    <span className="block">support@megauae.pro</span>
                  </p>
                </li>
                <li className="flex items-center mb-5 lg:mb-10">
                  <img
                    src={clock}
                    alt="time"
                    title="Open at..."
                    className="w-10"
                  />
                  <p className="mb-0 ml-5">
                    Monday to Friday:{" "}
                    <span className="block">9:30 - 21:00 GMT</span>
                  </p>
                </li>
                <li className="flex items-center mb-5 lg:mb-10">
                  <img
                    src={phone}
                    alt="call"
                    title="Call us"
                    className="w-10"
                  />
                  <p className="mb-0 ml-5">
                    Telephone number:{" "}
                    <span className="block">+357 00 000 000</span>
                  </p>
                </li>
              </ul>
            </article>
          </div>
        </div>

        <Footer />
      </section>
    </>
  )
}
