export default function TermsAndConditions() {
  return (
    <>
      <section className="px-5 xl:px-0 xl:max-w-7xl xl:mx-auto py-10">
        <h4 className="uppercase text-xl tracking-widest">Agreement</h4>
        <h1 className="text-center uppercase lg:text-left xl:px-0">
          Terms of service
        </h1>
        <div className="underline-header mx-auto mt-2 mb-8 lg:ml-0"></div>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:gap-10 mt-5 xl:mt-10">
          <div>
            <article className="mb-10">
              <h3 className="font-bold text-xl mb-2">Disclaimer</h3>
              <p>
                You should familiarize yourself with the things listed here.
                When you enter the website and its subdomains, you are accepting
                to abide by the stated terms and conditions. In case you are in
                a disagreement with them, then you should not enter the website.
              </p>
            </article>
            <article className="mb-10">
              <h3 className="font-bold text-xl mb-2">Trademarks</h3>
              <p>
                Copies of the contents may be viewed and downloaded for your own
                personal non-commercial use, provided that any copy contains
                this Copyright/Disclaimer Notice. The contents may not otherwise
                be used in whole or in part in any manner without the prior
                written consent of Megauae.
              </p>
              <p>
                However, it is permissible to use the website contents for
                viewing and copying, but only if it is for private and not
                commercial usage. It is again, part of the rules you agree to
                follow by entering the website. Please note that any other usage
                of the contents found on Megauae is not allowed without
                permission.
              </p>
            </article>
          </div>

          <div>
            <article className="mb-10">
              <h3 className="font-bold text-xl mb-2">
                Usage of information and other files
              </h3>
              <p>
                All of the information and rules contained on the website might
                be changed at some point in time. The usage of Megauae and its
                subpages with the purpose of misusing passwords, messing with
                the website`s system and misusage of materials is strictly
                forbidden. Note that some locations might not be able to access
                certain aspects of the website. Megauae saves its right to be
                the arbiter of determining access rights to the platform.
              </p>
            </article>
            <article className="mb-10">
              <h3 className="font-bold text-xl mb-2">No warranty provided</h3>
              <p>
                All imagery, articles or other information mediums are provided
                "as they are" and are all part of the Megauae platform, but No
                Megauae is not liable for the correctness or consistency of the
                materials. Along with that, Megauae is not liable for any
                errors or misinterpretations that might be seen in the
                materials. We cannot give a guarantee regarding any of the
                imagery, texts or any other materials provided on the website.
                This is also true for the infringement of 3rd party rights or
                any other claims that might arise, be it due to wrongful
                information, malware or anything else.
              </p>
            </article>
          </div>
        </div>
      </section>
    </>
  )
}
