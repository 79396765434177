import { useState } from "react"
import { faqs } from "../../data/data"
import SingleQuestion from "./SingleQuestion"

export default function AccountFaqs() {
  const [quests] = useState(faqs)

  return (
    <>
      <section className="py-10 lg:relative px-5 xl:px-0" id="faqs">
        <h2 className="lg:absolute left-0 top-10 lg:w-1/2 px-5 lg:rounded-r-full lg:text-center xl:px-0">
          Account FAQs
        </h2>
        <div className="underline"></div>

        <div className="pt-5 px-5 lg:pt-20 lg:mt-10 xl:max-w-4xl xl:mx-auto">
          {quests.map(({ id, question, answer1, answer2 }) => (
            <SingleQuestion
              key={id}
              question={question}
              answer1={answer1}
              answer2={answer2}
            />
          ))}
        </div>
      </section>
    </>
  )
}
